.date-picker-wrapper.custom-styles {
  width: 100%
}

.react-datepicker-wrapper {
  width: 100%;
}

.date-picker-wrapper.custom-styles .react-datepicker__input-container input {
  width: 100%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.date-picker-wrapper.custom-styles .react-datepicker-popper {
  .react-datepicker {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
  }
}