.code-block {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 10px;
  font-family: monospace;
  position: relative;
}

.code-content {
  white-space: pre-wrap;
  margin: 0;
  overflow-x: auto;
}
